// Import dependance here
import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Import files here
import Interview from "./interview/index";
import AutoScreenInterview from "./component/AutoScreen/interview/index";

import OtpScreen from "./component/OtpScreen/OtpScreen";

import GuideLines from "./component/GuideLines/GuideLines";
import AutoScreenGuideLines from "./component/AutoScreen/GuideLines/GuideLines";

import PageNotFound from "./component/PageNotFound/PageNotFound";
import ThankYou from "./component/ThankYou/ThankYou";
import Feedback from "./component/Feedback/Feedback";
import { Provider } from "react-redux";
import store from "./redux/store";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import VideoCallOtpScreen from "./component/VideoCall/VideoCallOtpScreen/VideoCallOtpScreen";
import VideoCallGuidelines from "./component/VideoCall/VideoCallGuidelines/VideoCallGuidelines";
import VideoCallLobbyScreen from "./component/VideoCall/VideoCallLobbyScreen/VideoCallLobbyScreen";
import VideoCallRoom from "./component/VideoCall/VideoCallRoom/VideoCallRoom";

import ExternalInterviewOtpScreen from "./component/ExternalInterview/ExternalInterviewOtpScreen/ExternalInterviewOtpScreen";
import ExternalInterviewGuidelines from "./component/ExternalInterview/ExternalInterviewGuidelines/ExternalInterviewGuidelines";
import ExternalInterviewLobbyScreen from "./component/ExternalInterview/ExternalInterviewLobbyScreen/ExternalInterviewLobbyScreen";
import ExternalInterviewRoom2 from "./component/ExternalInterview/ExternalInterviewRoom/ExternalInterviewRoom2";

import { SocketProvider } from "./context/SocketProvider";

function App() {
  const handle = useFullScreenHandle();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [showPopup,setShowPopup]=useState(false)
  const handleFullScreenChange = (state) => {
    setIsFullScreen(state);
  }; 
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const user_uuid = localStorage.getItem("resourceful_video_call_id")
  const interview_id = localStorage.getItem("resourceful_video_call_room");

  useEffect(() => {
    // Function to check network status via a simple fetch request
    const checkNetworkStatus = async () => {
      try {
        const response = await fetch('https://jsonplaceholder.typicode.com/posts/1', {
          method: 'HEAD',
          cache: 'no-store',
          mode: 'cors',
          headers: {
            'Access-Control-Allow-Origin':'*'
          }
        });
        
        if (response.ok) {
          setIsOnline(true);
        } else {
          setIsOnline(false);
        }
      } catch (error) {
        setIsOnline(false);
      }
    };

    // Function to handle online event
    const handleOnline = () => {
      console.log('Online event triggered');
      setIsOnline(true);
    };

    // Function to handle offline event
    const handleOffline = () => {
      setIsOnline(false);
    };

    // Initial check for online status
    checkNetworkStatus();

    // Check network status every 30 seconds
    const intervalId = setInterval(checkNetworkStatus, 30000);

    // Add event listeners
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Cleanup on component unmount
    return () => {
      clearInterval(intervalId);
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    const uuid = localStorage.getItem("uuid");
    if(isOnline) {
      
      console.log('Online event triggered');
    } else {
      
      console.log('Offline event triggered');
      setShowPopup(true)
      setTimeout(() => {
        const currentPath = window.location.pathname;
        const pathParts = currentPath.split('/')[1];
        const extractedPath = pathParts.split('-').slice(0, 2).join('-');
        
        if(extractedPath === "external-interview"){
          window.location.href = `/external-interview-otp-screen?id=${user_uuid}&room=${interview_id}`;
        }
        else if(extractedPath === "video-call"){
          window.location.href = `/video-call-otp?id=${user_uuid}&room=${interview_id}`;
        }
        else{
          const uuid = localStorage.getItem("uuid");
          window.location.href = '/'+uuid
        }
      }, 30000);
    }
  },[isOnline])


  function isMobile() {
    if(/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      setIsMobileDevice(true)
    }
  }

  useEffect(() => {
    setIsMobileDevice(false)
    isMobile();
  }, []);
  return (
    <FullScreen handle={handle} onChange={handleFullScreenChange}>
      <div className='h-screen bg-[#fff]'>
        <script src='https://www.webrtc-experiment.com/screenshot.js'></script>
       
        <Provider store={store}>  
          <Router>
            <SocketProvider>
              <Routes>
                <Route
                  path='/:uuid'
                 
                  element={<OtpScreen allowedOtpLength={6}  isOnline={isOnline}  isMobileDevice={isMobileDevice} />}
                />
                <Route
                  path='/guidelines/:uuid'
                  element={
                    <GuideLines
                      handle={handle}
                      setIsFullScreen={setIsFullScreen}
                    />
                  }
                />
                <Route
                  path='/interview/:uuid'
                  element={
                    <Interview
                      isFullScreen={isFullScreen}
                      handle={handle}
                      setIsFullScreen={setIsFullScreen}
                    />
                  }
                />
                <Route path='/thank-you' element={<ThankYou />} />
                <Route path='/feedback' element={<Feedback />} />
                <Route path='/page-not-found' element={<PageNotFound />} />

                {/*  video call url start  */}
                <Route
                  path='/video-call-otp'
                  element={<VideoCallOtpScreen />}
                />
                <Route path='/guidelines' element={<VideoCallGuidelines />} />
                <Route
                  path='/video-call-lobby'
                  element={<VideoCallLobbyScreen />}
                />
                <Route
                  path='/video-call-room/:id'
                  element={<VideoCallRoom />}
                />
                <Route
                  path='/autoscreen/guidelines/:uuid'
                  element={
                    <AutoScreenGuideLines
                      handle={handle}
                      setIsFullScreen={setIsFullScreen}
                    />
                  }
                />
                <Route
                  path='/autoscreen/interview/:uuid'
                  element={
                    <AutoScreenInterview
                      isFullScreen={isFullScreen}
                      handle={handle}
                      setIsFullScreen={setIsFullScreen}
                    />
                  }
                />
                {/*  video call url end  */}

                {/*  External Interview url start  */}
                <Route
                  path='/external-interview-otp-screen'
                  element={<ExternalInterviewOtpScreen />}
                />
                <Route
                  path='/external-interview-guidelines'
                  element={<ExternalInterviewGuidelines />}
                />
                <Route
                  path='/external-interview-lobby'
                  element={<ExternalInterviewLobbyScreen
                    handle={handle}
                    setIsFullScreen={setIsFullScreen} />}
                />
                <Route
                  path='/external-interview-room/:id'
                  element={<ExternalInterviewRoom2 
                    isFullScreen={isFullScreen}
                    handle={handle}
                    setIsFullScreen={setIsFullScreen}
                   />}
                />
                {/*  External Interview url end  */}
              </Routes>
            </SocketProvider>
          </Router>
        </Provider>
       {showPopup&&   <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
          <div
                    className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ${showPopup ? "opacity-100 translate-y-0 sm:scale-100 transition-transform duration-300" : "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95 transition-opacity duration-200"}`}
                  >
                    <div className='bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4'>
                      <div className='sm:flex items-center'>
                        <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                          <svg
                            className='text-red-600 w-6 h-6'
                            aria-hidden='true'
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 20 20'
                          >
                            <path
                              stroke='currentColor'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='2'
                              d='M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z'
                            />
                          </svg>
                        </div>
                        <div className='mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left'>
                          <h3
                            className='text-base font-semibold leading-6 text-gray-900'
                            id='modal-title'
                          >
                            Opps
                          </h3>
                          <div className="mt-2">
                          <p className="text-sm text-gray-500">
                          Connection Lost!! Please check your internet connection and try again.
                          </p>
                        </div>
                        </div>
                      </div>
                    </div>
                    <div className='bg-white px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
                      <button
                        type='button'
                        className='inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto'
                        onClick={() => {
                          const currentPath = window.location.pathname;
                          const pathParts = currentPath.split('/')[1];
                          const extractedPath = pathParts.split('-').slice(0, 2).join('-'); 
                          if(extractedPath === "external-interview"){
                            window.location.href = `/external-interview-otp-screen?id=${user_uuid}&room=${interview_id}`;
                          }
                          else if(extractedPath === "video-call"){
                            window.location.href = `/video-call-otp?id=${user_uuid}&room=${interview_id}`;
                          }
                          else{
                            const uuid = localStorage.getItem("uuid");
                            window.location.href = '/'+uuid
                          }
                        }}
                      >
                        Ok
                      </button>
                    
                    </div>
                  </div>
                  </div></div>  }
        </div>
    </FullScreen>
  );
}

export default App;
